@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima_Nova_Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima_Nova_Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima_Nova_Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima_Nova_Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("./assets/fonts/GT-Walsheim-Ultra-Light.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("./assets/fonts/GT-Walsheim-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("./assets/fonts/GT-Walsheim-Thin.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("./assets/fonts/GT-Walsheim-Regular.woff") format("woff");
  font-weight: 400;
  line-height: 26.4px;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("./assets/fonts/GT-Walsheim-Medium.woff") format("woff");
  font-weight: 500;
  line-height: 24.3px;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("./assets/fonts/GT-Walsheim-Bold.woff") format("woff");
  font-weight: 700;
  line-height: 30.8px;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("./assets/fonts/GT-Walsheim-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}



.no-padding {
  padding: 0;
}

.no-text-decoration {
  text-decoration: none;
  :focus, :hover, :visited, :link, :active {
      text-decoration: none;
  }
}


body {
  margin: 0;
  font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
